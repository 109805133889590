import React, { Component } from "react"
import logo from "../images/logo.png"
import "bootstrap/dist/css/bootstrap.css"
import "../components/layout.css"
import "../components/skeinetStyles.css"

class IndexPage extends Component {
  state = {}

  handleClick = () => {
    window.location = "/menu/menu"
  }

  render() {
    return (
      <header className="App-header">
        <img
          src={logo}
          className="c-pointer"
          alt="logo"
          onClick={this.handleClick}
        />
      </header>
    )
  }
}

export default IndexPage
